import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
  url,
  integer,
} from '@vuelidate/validators';
import { EFormPaymentTypes } from './form.types';

const requiredCustomValidator = (value: string) => {
  return value.length > 0;
};

const validations = {
  required(message: string = 'The field is required') {
    return helpers.withMessage(message, required);
  },
  email: helpers.withMessage('Invalid email address', email),
  url: helpers.withMessage('The link must contain http:// или https://', url),
  integer: helpers.withMessage('Only numbers', integer),
  minLength(value: number) {
    return helpers.withMessage(
      `The field must contain at least ${value} characters`,
      minLength(value),
    );
  },
  maxLength(value: number) {
    return helpers.withMessage(
      `The field must contain a maximum of ${value} characters`,
      maxLength(value),
    );
  },
  phoneMin(value: number) {
    return helpers.withMessage('The number must contain at least 11 digits', minLength(value));
  },
  phoneMax(value: number) {
    return helpers.withMessage('The number must contain a maximum of 11 digits', maxLength(value));
  },
  requiredRequisites(value: string | null) {
    const message =
      value === EFormPaymentTypes.PHONE
        ? 'Enter the phone number for the deposit'
        : 'Enter the card number or phone number that the card is linked to';

    const requiredRequisitesWithParams = helpers.withParams({ message }, requiredCustomValidator);

    return helpers.withMessage(({ $params }) => $params.message, requiredRequisitesWithParams);
  },
};

export default validations;
