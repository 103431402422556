import type { VNode } from 'vue';

export enum EFormStates {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum EFormStateMessages {
  SUCCESS_TITLE_DEFAULT = ' Request received.',
  SUCCESS_TITLE_OTK = 'Thanks for the assessment!',
  SUCCESS_SUBTITLE_DEFAULT = 'A specialist will contact you within 15 minutes!',
  SUCCESS_SUBTITLE_CASHBACK = 'Expect to be credited within 14 business days',
  SUCCESS_SUBTITLE_OTK = 'You are very helpful to other users',
  ERROR_TITLE_DEFAULT = 'Error!',
  ERROR_SUBTITLE_DEFAULT = 'It was not possible to send the data, try again.',
}

export enum EFormPaymentTypes {
  PHONE = 'phone',
  CARD = 'card',
}

/**
 * @description Form props
 */
export interface IFormProps {
  /**
   * @description Success title text
   * @type string
   * @default 'Заявка получена'
   */
  successTitle?: string;

  /**
   * @description Success subtitle text
   * @type string
   * @default 'Специалист свяжется с вами в течении 14 дней!'
   */
  successSubtitle?: string;

  /**
   * @description Error title text
   * @type string
   * @default 'Ошибка!'
   */
  errorTitle?: string;

  /**
   * @description Error subtitle text
   * @type string
   * @default 'Не удалось отправить данные, попробуйте еще раз.'
   */
  errorSubtitle?: string;
}

/**
 * @description Form slots
 */
export interface IFormSlots {
  /**
   * @description default component slot
   */
  default?(): VNode[];

  /**
   * @description policy text component slot
   */
  policyText?(): VNode[];

  /**
   * @description button text component slot
   */
  buttonText?(): VNode[];
}

/**
 * @description Form emits
 */
export interface IFormEmits<T> {
  (e: 'submit', formData: T): void;
  (e: 'update:state', payload: EFormStates): void;
}
